<template>
  <v-col cols="12" sm="12" md="3">
    <v-btn
      dark
      rounded
      large
      block
      class="px-2"
      :to="{ name: 'AnnualDeclaration' }"
      color="info"
    >
      informe de rendimentos
      {{ $moment().subtract(1, 'years') | moment('YYYY') }}
    </v-btn>
  </v-col>
</template>
<script>
export default {
  methods: {
    navigateTo(routerPath) {
      this.$router.push({ path: routerPath })
    },
    emitClikButton() {
      this.$emit('clikButton', {})
    },
  },
}
</script>
