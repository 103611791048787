<template>
  <v-container class="px-0 py-0 mt-0">
    <v-row justify="center" class="page__buttons mt-0 cols">
      <v-col cols="12" sm="12" md="3">
        <v-btn
          dark
          rounded
          large
          @click="navigateTo('/impostos')"
          color="orange"
          block
        >
          <v-icon left>fa-university</v-icon> PAGAR IMPOSTOS
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-btn
          dark
          block
          rounded
          large
          @click="emitClikButton()"
          color="#d374dc"
        >
          <!-- @click="" -->
          <v-icon medium left>trending_up</v-icon> REGISTRAR DESPESA
        </v-btn>
      </v-col>
      <btn-annual-declaration />
    </v-row>
  </v-container>
</template>

<script>
import btnAnnualDeclaration from './btn-annual-declaration.vue'
export default {
  components: {
    btnAnnualDeclaration,
  },
  methods: {
    navigateTo(routerPath) {
      this.$router.push({ path: routerPath })
    },
    emitClikButton() {
      this.$emit('clikButton', {})
    },
  },
}
</script>

<style scoped lang="sass">

.page__buttons
  margin: 40px 0

  .flex
    display: flex
    justify-content: center
</style>
